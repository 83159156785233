















import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class SuccessTip extends Vue {}
